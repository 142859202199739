exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-banner-page-1-tsx": () => import("./../../../src/pages/banner-page-1.tsx" /* webpackChunkName: "component---src-pages-banner-page-1-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-compliance-tsx": () => import("./../../../src/pages/compliance.tsx" /* webpackChunkName: "component---src-pages-compliance-tsx" */),
  "component---src-pages-conflict-of-interest-tsx": () => import("./../../../src/pages/conflict-of-interest.tsx" /* webpackChunkName: "component---src-pages-conflict-of-interest-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-fa-qs-tsx": () => import("./../../../src/pages/FAQs.tsx" /* webpackChunkName: "component---src-pages-fa-qs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insurance-basics-tsx": () => import("./../../../src/pages/insurance-basics.tsx" /* webpackChunkName: "component---src-pages-insurance-basics-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-comprehensive-funeral-benefit-tsx": () => import("./../../../src/pages/products/comprehensive-funeral-benefit.tsx" /* webpackChunkName: "component---src-pages-products-comprehensive-funeral-benefit-tsx" */),
  "component---src-pages-products-comprehensive-legal-insurance-tsx": () => import("./../../../src/pages/products/comprehensive-legal-insurance.tsx" /* webpackChunkName: "component---src-pages-products-comprehensive-legal-insurance-tsx" */),
  "component---src-pages-products-group-funeral-benefit-tsx": () => import("./../../../src/pages/products/group-funeral-benefit.tsx" /* webpackChunkName: "component---src-pages-products-group-funeral-benefit-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-life-and-live-secure-benefit-tsx": () => import("./../../../src/pages/products/life-and-live-secure-benefit.tsx" /* webpackChunkName: "component---src-pages-products-life-and-live-secure-benefit-tsx" */),
  "component---src-pages-products-platinum-plus-funeral-benefit-tsx": () => import("./../../../src/pages/products/platinum-plus-funeral-benefit.tsx" /* webpackChunkName: "component---src-pages-products-platinum-plus-funeral-benefit-tsx" */),
  "component---src-pages-products-popcru-25-funeral-benefit-tsx": () => import("./../../../src/pages/products/popcru-25-funeral-benefit.tsx" /* webpackChunkName: "component---src-pages-products-popcru-25-funeral-benefit-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-why-workerslife-tsx": () => import("./../../../src/pages/why-workerslife.tsx" /* webpackChunkName: "component---src-pages-why-workerslife-tsx" */),
  "component---src-pages-workerslife-careers-general-manager-vacancy-index-tsx": () => import("./../../../src/pages/workerslife-careers/general-manager-vacancy/index.tsx" /* webpackChunkName: "component---src-pages-workerslife-careers-general-manager-vacancy-index-tsx" */),
  "component---src-pages-workerslife-careers-index-tsx": () => import("./../../../src/pages/workerslife-careers/index.tsx" /* webpackChunkName: "component---src-pages-workerslife-careers-index-tsx" */)
}

